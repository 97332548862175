/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

.container{
	width: 90%;
    max-width: 900px;
	margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}
 .div-headerImage{
    height: 187px;
    overflow: hidden;
    background:url(./header.png) no-repeat center center;
}
.title{
	font-size: 24px;
	font-family: arial;
	color: #006fba;
}
.preFormText{
	font-size: 16px;
	font-family: arial;
	color: #006fba;
}
.label{
	font-size: 16px;
	font-family: arial;
	color: #006fba;
}
.labelContainer{
	width: 150px;
	display: inline-block;
}
.inputText{
	padding: 5px;
	width: 315px;
	font-family: arial;
}
.inputContainer{
	display: inline;
}
.submitButton{
	background-color: #006fba;
	padding: 12px 0px;
	font-family: arial;
	color: #ffffff;
	border: 0px;
	width: 186px;
	font-size: 16px;
    cursor: pointer;
}
#UnsubscribeMore.submitButton{
    width: 200px;
}
.formElement{
	padding: 20px 0px 10px 0px;
}
.legal{
	font-family: arial;
	font-size: 11px;
	color: #006fba;
	margin-top: 30px;
}
.errorText{
    color: red;
    font-family: arial;
    font-size: 12px;
    padding-left: 160px;
    padding-top: 5px;
}
.errorText-blink{
  animation: blinkMe 0.1s linear;
  animation-iteration-count: 3;
  color: red;
  font-family: arial;
  font-size: 12px;
  padding-left: 160px;
  padding-top: 5px;
}
@keyframes blinkMe {
  50% {
    opacity: 0;
  }
}
#emailError3.errorText {
    padding-left: 30px;
}
#emailError3.errorText-blink {
  padding-left: 30px;
}
@media only screen and (max-width:550px) {
    .labelContainer{
        /*display: block;*/
        padding: 0px 0px 5px 0px;
    }
    .errorText{
        /*display: block;*/
        padding: 5px 0px 0px 0px;
    }
    .inputText{
        width: 100%;
    }
}


.label-cbx {
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
}
.label-cbx input:checked + .checkbox {
  border-color: #006fba;
}
.label-cbx input:checked + .checkbox svg path {
  fill: #006fba;
}
.label-cbx input:checked + .checkbox svg polyline {
  stroke-dashoffset: 0;
}
.label-cbx:hover .checkbox svg path {
  stroke-dashoffset: 0;
}
.label-cbx .checkbox {
  position: relative;
  top: 2px;
  float: left;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  border: 2px solid #C8CCD4;
  border-radius: 3px;
}
.label-cbx .checkbox svg {
  position: relative;
  top: -2px;
  left: -2px;
}
.label-cbx .checkbox svg path {
  fill: none;
  stroke: #006fba;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 71px;
  stroke-dashoffset: 71px;
  transition: all 0.6s ease;
}
.label-cbx .checkbox svg polyline {
  fill: none;
  stroke: #FFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  transition: all 0.3s ease;
}
.label-cbx > span {
  pointer-events: none;
  vertical-align: middle;
}

.cntr {
  position: relative;
  top: 45%;
  left: 0;
  width: 100%;
}

.invisible {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
}

.subscribe {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  color: #006fba;
  margin-top: 10rem;
  height: 5rem;
  width: 5rem;
}

@keyframes spin{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
